import { css } from '@emotion/react' 
import { Component, memo } from 'react';
import { 
    Container,
    Typography,
    Grid,
    Box,
    Button,
    FormControlLabel,
    RadioGroup,
    FormControl,
    Radio,
    Snackbar,
} from '@material-ui/core';

import { CardElevation, CustomizedStepper, ModalInfo, Sliderv3 } from '@findep/mf-landings-core';
import { AttachMoney, LocationOnIcon, Home, AddComment, HowToReg as HowToRegIcon } from '@material-ui/icons';

import { StartService } from '../../services/LandingV4/start'
import { SimulatorService } from '../../services/LandingV4/simulator'
import { getPeriod } from '../../helpers/simulador'
import formatNumber from '../../helpers/formatNumber'
import { navigate } from '../../helpers/queryNavigate';
import { debounce } from 'lodash'
import { getCookieValue } from '../../helpers/cookies'
import credialto from '../../assets/altoperfil/credialto/credialto.png'
import lap from '../../assets/altoperfil/credialto/lapgirl.png'
import calendar from '../../assets/altoperfil/credialto/calendargirl.png'
import img1 from '../../assets/altoperfil/credialto/img1.jpg'
import img2 from '../../assets/altoperfil/credialto/img2.jpg'
import img3 from '../../assets/altoperfil/credialto/img3.jpg'
import { Alert } from '@material-ui/lab';
import FormapagoApp from '../elements/FormapagoApp';
import ButtonNavigate from '../elements/ButtonNavigate';
import { PureRastreo } from '../forms/Rastreo';


const marginCard = css`
    margin-top: 5px;
`
const cssSelect = css` 
  width:100%;
`

const firstBack = css`
    background: '#F8F8F8'; 
    position: 'relative'; 
    z-index: '-1'; 
    margin-top: '-780px'; 
    width: '200%'; 
    height: '450px';
`

let Start = []
let Simulator = []
class Simulador extends Component {
    constructor(props) {
        super(props);
        this.state = {
            matches: false,
            dedicacion: '',
            monto: '',
            plazo: '',
            period: 'QUINCENAL',
            terms: '',
            pago: '',
            flujo: '',
            isEmployed: 'S',
            open: false,
            errorService: false,
            minVar: null,
            maxVar: null,
            defaultAmountVar: null,
            incrementVar: null,
            modalRastreo: false
        }
        this.pagoEstimado = this.pagoEstimado.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.deleteFunction = this.deleteFunction.bind(this)
    }

    //FUNCIONES PARA EL MODAL
    openModal = () => {
        this.setState({ open: true })
    }
    onClickModalRastreo = () => {
        let value = this.state.modalRastreo; 
        this.setState({ modalRastreo: !value })
    }

    onChangeModal = (e) => {
        if (e.value === "ACEPTAR") {
            sessionStorage.removeItem('flujo');
            const navigateUrl = 'https://www.apoyoeconomico.com.mx/';
            navigate(navigateUrl);
        } else {
            this.setState({ ...this.state, isEmployed: 'S' })
            this.closeModal()
        }

    }

    closeModal = (e) => {
        this.setState({ open: false })
    }

    async componentDidMount() {
        let windowMatches = typeof window !== 'undefined' && window.matchMedia('(max-width: 720px)').matches;
        this.setState({ matches: windowMatches})

        let flujo = JSON.parse(sessionStorage.getItem('flujo'))
        if (!flujo?.simulador || !flujo.creditApplicationId) {
            let urlParams = new URLSearchParams(window.location.search)
            let broker = ''
            let tenant = this.props.pageContext.tenant || 'AEF'
            let origen

            const fbp = getCookieValue('_fbp')
            const fbc = getCookieValue('_fbc')

            if (urlParams.has('origen') || urlParams.has('broker')) {
                origen = urlParams.get('origen') || urlParams.get('broker')
                broker = origen
            }
            const source = urlParams.get('source') || urlParams.get('utm_source')
            const medium = urlParams.get('medium') || urlParams.get('utm_medium')
            const campaign = urlParams.get('campaign') || urlParams.get('utm_campaign')
            const term = urlParams.get('term') || urlParams.get('utm_term')
            const content = urlParams.get('content') || urlParams.get('utm_content')
            const operatorEmail = urlParams.get('operatorEmail')

            Start = new StartService(tenant, broker || this.props.pageContext.broker)

            Start.setUtmSource(source)
            Start.setUtmMedium(medium)
            Start.setUtmCampaign(campaign)
            Start.setUtmTerm(term)
            Start.setOperatorEmail(operatorEmail)
            Start.setUtmContent(content)
            Start.setBrowserId(fbp)
            Start.setClickId(fbc)

            try {

                const { data: startData } = await Start.send()

                flujo = {
                    ...flujo,
                    creditApplicationId: startData.id,
                    simulador: {
                        availableProducts: startData.availableProducts
                    },
                }

                const { forceRedirect } = startData
                if (forceRedirect !== null && forceRedirect !== undefined) {
                    navigate(forceRedirect)
                }

            } catch (e) {
                console.error(e)
                this.setState({ errorService: true })
            }

        }

        Simulator = new SimulatorService(flujo?.creditApplicationId)
        this.setState({ 
            flujo,
            dedicacion: flujo?.simulador?.availableProducts[0].id
        })
        sessionStorage.setItem('flujo', JSON.stringify(flujo))
    }
    
    componentDidUpdate(_, prevState) {
        const { terms, dedicacion, monto, flujo, period } = this.state        

        if (monto !== prevState.monto || period !== prevState.period || terms !== prevState.terms || dedicacion !== prevState.dedicacion) {
            if (monto && period && terms && dedicacion) {
                this.setState({ pago: '' })
                this.deleteFunction(dedicacion, monto, terms, period)
            }
        }
        if (dedicacion !== prevState.dedicacion) {
            const selectedProduct = flujo && flujo?.simulador && flujo?.simulador.availableProducts ?
                flujo?.simulador.availableProducts.find(producto => producto.id === dedicacion) : null

            if (selectedProduct !== null) {

                if (selectedProduct.max < 1 || selectedProduct.min < 1) {
                    this.setState({
                        minVar: selectedProduct && selectedProduct.min,
                        maxVar: selectedProduct && selectedProduct.min,
                        defaultAmountVar: selectedProduct && selectedProduct.min,
                        incrementVar: selectedProduct && selectedProduct.increment,
                        open: true
                    })
                } else {
                    if (selectedProduct.defaultAmount >= selectedProduct.min && selectedProduct.defaultAmount <= selectedProduct.max) {                        
                        this.setState({
                            minVar: selectedProduct && selectedProduct.min,
                            maxVar: selectedProduct && selectedProduct.max,
                            defaultAmountVar: selectedProduct && selectedProduct.defaultAmount,
                            incrementVar: selectedProduct && selectedProduct.increment
                        })
                    } else {
                        this.setState({
                            minVar: selectedProduct && selectedProduct.min,
                            maxVar: selectedProduct && selectedProduct.max,
                            defaultAmountVar: selectedProduct && selectedProduct.min,
                            incrementVar: selectedProduct && selectedProduct.increment,
                            open: true
                        })
                    }
                }
            }
        }        
    }

    async pagoEstimado(productId, amount, terms, period) {        
        this.setState({ loading: true })
        Simulator.setProductId(productId)
        Simulator.setRequestedAmount(amount)
        Simulator.setTerms(Number(terms))
        Simulator.setPeriod(getPeriod(period))
        try {
            const { data: dataSimulator } = await Simulator.send()

            sessionStorage.setItem('cotizador', JSON.stringify(
                {
                    renta: dataSimulator.estimatedPayment,
                    cat: dataSimulator.cat,
                    monto: amount
                }
            ))
            this.setState({ pago: dataSimulator.estimatedPayment })            
        } catch (e) {
            console.error(e)
            this.setState({ errorService: true })
            this.goErrorPath()
        } finally {
            this.setState({ loading: false })
        }
    }

    goErrorPath() { setTimeout(navigate(this.props.pageContext.errorPath), 250) }

    deleteFunction = debounce(function (dedicacion, monto, terms, period) {
        this.pagoEstimado(dedicacion, monto, terms, period)
    }, 250);

    handleChange(element, data) {
        (element === 'isEmployed' && data.value === 'N') && this.openModal()
        this.setState({ [element]: data.value })
    }

   

    render() {
        const { company } = this.props.pageContext

        const icons = { 1: <Home />, 2: <HowToRegIcon />, 3: <AddComment />, 4: <AttachMoney /> }
        const texts = [{ texto: 'DATOS PERSONALES' }, { texto: 'VALIDACIÓN' }, { texto: 'DATOS ADICIONALES' }, { texto: 'OFERTA' }]
        const datosFP = {
            formaPago: " 36 quincenas",
            tituloSelect: "¿En qué plazo lo quieres pagar?",
            colorEmpresa: 'primary'
        }

        const { dedicacion, monto, flujo, period } = this.state

        const selectedProduct = flujo && flujo?.simulador && flujo?.simulador.availableProducts ?
        flujo?.simulador.availableProducts.find(producto => producto.id === dedicacion) : null

        let isProductAvailable = flujo?.simulador?.availableProducts?.filter(element => element.displayName)

        isProductAvailable = isProductAvailable?.length > 1

       
        
        return (
            <Container style={{ position: 'relative', zIndex: '100' }} >                
                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                >
                    <div style={{ 
                        background: '#F8F8F8', 
                        position: 'absolute', 
                        zIndex: '-1', 
                        marginTop: this.state.matches ? '-990px' : '-1120px', 
                        width: '100vw', 
                        height: this.state.matches ? '950px' : '1050px' }}                             
                    />
                    <div style={{ 
                        background: 'linear-gradient(#00C3B3, #255697)', 
                        position: 'absolute', 
                        zIndex: '-2', 
                        marginTop: this.state.matches ? '1760px' :'380px', 
                        width: '100vw', 
                        height: this.state.matches ? '1200px' : '550px' }} 
                    />
                    {!this.state.matches && <div style={{ 
                        background: '#F8F8F8', 
                        position: 'absolute', 
                        zIndex: '-3', 
                        marginTop: '2000px', 
                        width: '100vw', 
                        height: '2000px' }} />}
                    <img src={credialto} alt="logo credialto" width="250opx" />  

                     <PureRastreo color="primary" company={company} open={this.state.modalRastreo} onClose={this.onClickModalRastreo} />

                    <Grid
                        container
                        direction="container"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Grid item style={{ textAlign: "center"}}>
                            <Box mt={4}>
                                <img src={img1} alt="Banner Nos identificamos contigo, te ayudamos a crecer" width={this.state.matches ? '100%' : '70%'}/>
                            </Box>
                        </Grid>
                        
                        <Grid item md={6} xs={12}>
                            <Box mt={!this.state.matches && 2}>  
                                <CustomizedStepper altoPerfil iconos={icons} currentStep={-1} texts={texts} />
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid container spacing={this.state.matches ? 5 : 8} css={marginCard}>
                        <Grid item md={6} xs={12}>
                            <CardElevation altoPerfil colorBackgroundHeader='#cfebec'>                           
                                    <Grid item>
                                    <Grid
                                            container
                                            direction="row"
                                            justifyContent="flex-end"
                                            alignItems="flex-end"   
                                            style={{ marginTop: '-143px' }}                     
                                        >
                                        <Button onClick={this.onClickModalRastreo} color='primary' size="small" style={{ fontSize: this.state.matches ? '0.6rem' : '0.8rem' }}>Recupera tu solicitud</Button>
                                    </Grid> 
                                    </Grid> 
                                <Grid
                                    container
                                    direction="column"
                                    justifyContent="center"
                                    alignItems="center"
                                >                                    
                                    <img src={lap} alt="logo credialto" width="35%" />
                                    <Box mt={2}>                        
                                        <Grid item xs={12} align="center">
                                            <FormControl component="fieldset" style={{ alignItems: "center" }}>   
                                                <Grid item xs={12}>
                                                    <Typography variant="h6" component="h6">
                                                        <b>¿Puedes comprobar ingresos con recibos de nómina? </b>
                                                    </Typography>
                                                </Grid>                                     
                                                <RadioGroup aria-label="isEmployed" name="isEmployed" value={this.state.isEmployed} onChange={(_event, component) => this.handleChange("isEmployed", { value: _event.target.value })} row style={{ marginLeft: '30px' }}>
                                                    <FormControlLabel value="S" control={<Radio id="begin-employed-yes"/>} label="Si" />
                                                    <FormControlLabel value="N" control={<Radio id="begin-employed-no"/>} label="No"/>
                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>
                                    </Box>
                                    <Box mt={2}>
                                        <Typography variant="h6" component="h6">
                                            <b>¿Cuánto dinero necesitas?</b>
                                        </Typography>
                                    </Box>
                                    {this.state.maxVar && <Sliderv3 
                                        altoPerfil  
                                        title="" 
                                        colorMount="#30358E"
                                        colorSlider="#0C9DA9" 
                                        valMinimo={this.state.minVar}
                                        valMaximo={this.state.maxVar}
                                        avance={1000}
                                        disabled={!dedicacion && isProductAvailable}
                                        onChange={(event) => this.handleChange('monto', {value: event})}
                                        id="simulador-monto"
                                        defaultValue={selectedProduct?.defaultAmount} 

                                    />}
                                </Grid>
                            </CardElevation>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <CardElevation altoPerfil colorBackgroundHeader='#cfebec'>
                            <Grid
                                container
                                direction="column"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <img src={calendar} alt="logo credialto" width="35%" style={{ marginTop: '-140px' }}/>
                            </Grid>
                            <Box mt={5}>
                                <Typography variant='h6' align='center'><b>¿Cómo quieres pagarlo?</b></Typography>
                            </Box>
                            <Box mt={2}>
                            <Grid item xs={12} align='center'>
                                <FormapagoApp fullWidth colorLabelTitle='#00c3b3' withOptionButton colorButton='#cfebec' removeDialog handleChange={this.handleChange} company={company} dedicacion="FTCS" monto={monto} configuration={datosFP} />
                            </Grid>
                            </Box>
                            <Box mt={2} align='center'>
                            <ButtonNavigate 
                                    id="simulador-continuar"
                                    disabled={true}
                                    customizeButton 
                                    textButton='¡Vamos! Continuar solicitud'
                                    // onClick={() => this.pagoEstimado(period)}
                                    company={this.props.pageContext.company}
                                    fallbackUrl={this.props.pageContext.next.path}
                                    journey={this.props.pageContext.journey}
                                    product={dedicacion}
                                />
                            </Box>
                            <br />
                        </CardElevation>
                    </Grid>
                      
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Grid item md={8} xs={10}>
                            <Box mt={4}>
                                <CardElevation>
                                    <Grid
                                        container
                                        direction="column"
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <Box mt={2}>
                                            <Typography variant="h4" style={{ color: '#225697' }}><b>Pago estimado</b></Typography>
                                        </Box>
                                        <Box mt={2}>
                                            <Typography variant={this.state.matches ? 'h5' : 'h3'} style={{ color: '#30358E', whiteSpace: 'nowrap' }}><b>{`${formatNumber(this.state.pago, 3, ',', '$', 0, '')} ${this.state.period}`}</b></Typography>
                                        </Box>
                                        <Box mt={1}>
                                        <Typography paragraph style={{ marginBottom: this.state.matches ? '15px' : '30px', textAlign: 'center', fontSize: '13px' }}>Calculado con tasa anual fija del 40%. <br /> *Recuerda que las condiciones y montos otorgados pueden variar conforme a la evaluación de tu perfil.</Typography>
                                        </Box>
                                        <Box mt={1} style={{ marginBottom: this.state.matches ? '-220px' : '-240px', position: 'absolute', zIndex: '1' }} >
                                        <ButtonNavigate 
                                            id="simulador-continuar"
                                            disabled={true}
                                            customizeButton 
                                            textButton='¡Vamos! Continuar solicitud'
                                            styleButton={{ backgroundColor: '#e0e0e0', textColor: '#a6a6a6' }}
                                            // onClick={() => this.pagoEstimado(period)}
                                            company={this.props.pageContext.company}
                                            fallbackUrl={this.props.pageContext.next.path}
                                            journey={this.props.pageContext.journey}
                                            product={dedicacion}
                                        />
                                        </Box>
                                    </Grid>
                                </CardElevation>                                
                            </Box>
                        </Grid>
                        <Grid item md={9} xs={10}>
                            <Box mt={8}>
                                <Grid
                                    container
                                    direction="column"
                                    justifyContent="center"
                                    alignItems="center"
                                    >
                                        <img src={img2} alt="Banner Nos identificamos contigo, te ayudamos a crecer" width='100%'/>
                                </Grid>
                            </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                </Grid>
               
                <Grid item>
                    <Box mt={this.state.matches ? 5 : 14}>
                        <Typography variant='h3' style={{ textAlign: "center", color: this.state.matches ? '#FFFFFF' : '#000000', fontSize: this.state.matches && '20px', fontWeight: "normal" }}>¡Tu  CRÉDITO</Typography>
                        <Typography variant='h3' style={{ textAlign: "center", color: this.state.matches ? '#FFFFFF' : '#000000', fontSize: this.state.matches && '20px' }}>con <b>pagos fijos!</b></Typography>
                    </Box>
                </Grid>
                <Grid item>
                    <Box mt={2}>
                        <Typography variant='h5' style={{ textAlign: "center", color: this.state.matches ? '#FFFFFF' : '#000000', fontSize: this.state.matches && '15px' }}>Desde el inicio sabes cuánto vas a pagar <b>en cada fecha</b></Typography>
                    </Box>
                </Grid>

                {this.state.matches && 
                    <Box mt={4}>
                        <img src={img3} alt="Banner nos adaptamos a tus necesidades" width='100%'/>
                    </Box>
                }
                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Box mt={4}>
                        <ButtonNavigate 
                            id="simulador-continuar"
                            disabled={true}
                            customizeButton 
                            textButton='Llenar mi solicitud'
                            styleButton={{ backgroundColor: '#e0e0e0', textColor: '#a6a6a6', padding: this.state.matches ? '10px 50px 10px 50px' : '20px 80px 20px 80px' }}
                            variant="outlined"
                            //Desactivar botón
                            // onClick={() => this.pagoEstimado(period)}
                            company={this.props.pageContext.company}
                            fallbackUrl={this.props.pageContext.next.path}
                            journey={this.props.pageContext.journey}
                            product={dedicacion}
                        />
                    </Box>
                </Grid>
                {!this.state.matches && <Box mt={5} style={{ textAlign: "center" }}>
                    <img src={img3} alt="Banner nos adaptamos a tus necesidades" width='70%'/>
                </Box>}
                <ModalInfo
                  onClose={this.closeModal}
                  autoClose={false}
                  icon={LocationOnIcon}
                  title="Lo sentimos"
                  color="primary"
                  btnLeftColor="#000000"
                  backgroundButton
                  open={this.state.open}
                  body={<div><Typography paragraph>Por el momento no podemos ofrecerte el servicio, en Apoyo Económico Familiar tenemos mas opciones para ti</Typography></div>}
                  btnLeft="ACEPTAR"
                  btnRight="CANCELAR"
                  toolTipButtonLeft="Te direccionaremos al sitio de Apoyo Económico Familiar"
                  toolTipButtonRight="Continuar en este sitio"
                  onChange={(data) => this.onChangeModal(data)}
                />
                <Snackbar open={this.state.errorService} autoHideDuration={6000} onClose={() => this.setState({ errorService: false })}>
                    <Alert variant="filled" severity="warning" onClose={() => this.setState({ errorService: false })}>
                        Lo sentimos, por el momento el servicio no se encuentra disponible
                    </Alert>
                </Snackbar>
            </Container>
        );
    }
}

export default memo(Simulador);